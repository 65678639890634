import { BlogComponent } from '@components/BlogComponent'
import Container from '@components/Container'
import { Pagination } from '@components/Pagination'
import SEO from '@components/SEO'
import { css, useTheme } from '@emotion/react'
import { BlogsQuery } from '@graphql-types'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-react-i18next'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { maxWidth } from 'src/themes'

export default function Events(
	props: PageProps<BlogsQuery, { currentPage: number; numBlogPages: number }>
) {
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<Container>
			<SEO title={t('layout:blog')} keywords={[`blog`]} />
			<h1>{t('layout:blog')}</h1>
			{props.data.blogs?.nodes?.map(
				(blog) => blog && <BlogComponent key={blog.path} blog={blog} />
			)}
			<Pagination
				totalPage={props.pageContext.numBlogPages}
				currentPage={props.pageContext.currentPage}
				link='/blog'
			/>
		</Container>
	)
}

export const query = graphql`
	query Blogs($language: String!, $skip: Int!, $limit: Int!) {
		...TranslationFragment
		blogs: allCockpitBlogCollection(
			filter: { active: { eq: true }, _lang: { eq: $language } }
			limit: $limit
			skip: $skip
			sort: { fields: date, order: DESC }
		) {
			nodes {
				path
				title
				caption
				author {
					name
					profileImg {
						path
					}
					profileImgSharp {
						childImageSharp {
							gatsbyImageData(width: 35, placeholder: BLURRED)
						}
					}
				}
				date
				coverImage {
					path
				}
				coverImageSharp {
					childImageSharp {
						gatsbyImageData(
							height: 300
							width: 300
							transformOptions: { fit: COVER }
						)
					}
				}
			}
		}
	}
`
