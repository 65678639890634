import { useTheme, css } from '@emotion/react'
import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { maxWidth } from 'src/themes'

export function Pagination(props: {
	totalPage: number
	currentPage: number
	link: string
}) {
	const theme = useTheme()
	return (
		<div
			css={css`
				display: flex;

				${maxWidth[2]} {
					flex-wrap: wrap;
					justify-content: center;
				}

				> div {
					border: solid 1px ${theme.textColor};
					margin: 1rem;
					border-radius: 1rem;

					a {
						display: block;
						padding: 1rem;
					}
				}
			`}>
			{Array.from({ length: props.totalPage }).map((_, i) => (
				<div
					css={css`
						background: ${i + 1 == props.currentPage
							? theme.textColor
							: 'transparent'};
						a {
							color: ${i + 1 == props.currentPage
								? theme.inverseColor
								: theme.textColor};
						}
					`}>
					<Link to={`${props.link}/${i == 0 ? '' : i + 1}`}>
						{i + 1}
					</Link>
				</div>
			))}
		</div>
	)
}
